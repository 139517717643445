.calendarWrapper {
  font-family: "sf pro text regular";
  padding: 0px 15px 0px 15px;
  width: auto !important;
}

.calendarHeader {
  padding: 0px 5px 5px 5px;
  border-bottom: 0px;
}

.dailyTimeLineWrapper {
  min-height: 660px;
}

.dailyEventTitle {
  line-height: normal;
}

.dailyEventWrapper {
  width: 20px !important;
  min-height: 28px !important;
  /* padding: 2px 10px 2px 10px !important; */
  padding: 0px !important;
  height: unset;
}

/* .dayEvent {
  text-align: center;
}

.dailyEventWrapper {
  width: 20px !important;
  padding: 0px !important;
}

.dailyEventTitle {
  line-height: normal;
} */

.modeWrapper {
  display: none;
}

.dailyHourLine {
  background-color: #e9e9e9;
}

::-webkit-scrollbar-track {
  border-radius: 0.125rem;
  background-color: #e9e9e900;
}
::-webkit-scrollbar {
  width: 0.2rem;
  border-radius: 0.125rem;
}
::-webkit-scrollbar-thumb {
  border-radius: 0.125rem;
  background-color: #dddddd;
}
