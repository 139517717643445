.title {
  position: fixed;
  text-align: left;
  color: #2e3192;
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
}

.button {
  height: 25px;
  text-align: center;
  background-color: #ffffff;
  color: #2e3192;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px #eeeeee;
}

.datelocalfield {
  width: 100%;
}

@media (max-width: 600px) {
  .datelocalfield {
    max-width: 250px;
  }
}

@media (max-width: 410px) {
  .datelocalfield {
    max-width: 155px;
  }
}

@media (max-width: 385px) {
  .addbookingaccordion {
    display: none;
  }
}
