.title {
  position: fixed;
  text-align: left;
  color: #2e3192;
  font-family: sf pro text regular;
  font-size: 18px;
  font-weight: bold;
}

.button {
  height: 25px;
  text-align: center;
  background-color: #ffffff;
  color: #2e3192;
  font-family: sf pro text regular;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px #eeeeee;
}
