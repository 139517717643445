.body {
  width: 100%;
  height: 100%;
}

.main {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  width: calc(100% - 50px);
  max-width: 1896px;
  height: calc(100% - 100px);
  padding: 10px;
  top: 60px;
}

.grid {
  display: grid;
  grid-template-columns: 0.1fr 3fr 1fr;
  grid-column-gap: 3%;
  height: 100%;
}

.grid>div:nth-child(1) {
  display: grid;
  grid-template-rows: 1fr 4fr;
  grid-row-gap: 3%;
}

.grid>div:nth-child(1)>div:nth-child(1) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2%;
  height: 100%;
}

.grid>div:nth-child(2) {
  display: grid;
  grid-template-rows: 1fr 7fr;
  grid-row-gap: 2%;
}

.card {
  background-color: #ffffff;
  border: 1px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 1px #00000001;
  position: relative;
}