@font-face {
  font-family: "sf pro text regular";
  src: local("SF Pro Text Regular"),
    url(./Resources/Fonts/SFProText-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "sf pro display regular";
  src: local("SF Pro Display Regular"),
    url(./Resources/Fonts/SFProDisplay-Regular.ttf) format("truetype");
}

.hide-resize-observer {
  display: none !important;
}