.logo {
  width: 80px;
  margin-left: 10px;
}

.navbar {
  width: 100vw;
  max-width: 1920px;
  height: 35px;
  padding: 4px;
  background-color: #ffffff;
  border: 1px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0px 0px 10px 1px #00000010;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
}

.buttondiv {
  float: right;
  margin-top: 10px;
  transform: translate(0%, -38%);
}

.button {
  width: 120px;
  height: 42px;
  left: 160px;
  margin: 0px;
  padding: 0px;
  text-align: center;
  background-color: #ffffff;
  color: #2e3192;
  font-family: sf pro text regular;
  font-size: 14px;
  font-weight: bold;
  border: 0px;
  border-left: 1px solid #dddddd;
  outline: none;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0);
}

.button:focus {
  background-color: rgba(0, 255, 255, 0);
}

.button:active {
  background-color: #dddddd;
}

/* Large desktop */
@media (min-width: 979px) and (max-width: 1200px) {
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
  .logo {
    width: 70px;
  }

  .navbar {
    height: 30px;
  }

  .button {
    height: 32px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .logo {
    width: 60px;
  }

  .navbar {
    height: 25px;
  }

  .button {
    width: 90px;
    height: 25px;
    font-size: 13px;
  }
}

/* Landscape phones and down */
@media (max-width: 480px) {
  .logo {
    width: 50px;
  }

  .navbar {
    height: 20px;
  }

  .button {
    width: 63px;
    height: 20px;
    font-size: 9px;
  }
}

@media (max-width: 350px) {
  .button {
    width: 47px;
    height: 20px;
    font-size: 8px;
  }
}

@media (max-width: 280px) {
  .navbar {
    display: none;
  }
}
