.box {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 92%;
  max-width: 1896px;
  height: 80%;
  padding: 15px;
  top: 50px;
  background-color: #ffffff;
}

.label {
  padding-top: 20px;
  text-align: center;
  color: #2e3192;
  font-family: Arial;
  font-size: 32px;
  font-weight: bold;
}
