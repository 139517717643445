.logo {
  width: 200px;
  max-width: 450px;
  min-width: 100px;
  padding: 10px;
}

.logodiv {
  width: 100vw;
  max-width: 1800px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
}

.box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 70%;
  max-width: 700px;
  height: 60%;
  max-height: 550px;
  margin-top: 50px;
  background-color: #ffffff;
  border: 1px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 1px #dddddd;
}

.label {
  padding-top: 20px;
  text-align: center;
  color: #2e3192;
  font-family: sf pro text regular;
  font-size: 32px;
  font-weight: bold;
}

.password {
  position: fixed;
  top: 180px;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 300px;
  width: 60%;
  height: 25px;
  text-align: center;
  color: #2e3192;
  border: 0px;
  border-bottom: 1px solid #2e31926e;
  padding-bottom: 3px;
  font-family: sf pro text regular;
  font-size: 18px;
  font-weight: bold;
  outline: none;
}

::placeholder {
  color: #2e31926e;
}

.button {
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 10%;
  height: 30px;
  text-align: center;
  border: 1px;
  border-radius: 5px;
  box-shadow: 0px 0px 1px 1px #dddddd;
  outline: none;
}

@media (min-width: 1200px) {
  .box {
    top: 140px;
    height: 400px;
  }
}

/* Large desktop */
@media (min-width: 979px) and (max-width: 1200px) {
  .logo {
    width: 200px;
    padding: 10px;
  }

  .box {
    top: 100px;
    height: 400px;
  }

  .label {
    padding-top: 15px;
    font-size: 26px;
  }

  .password {
    top: 165px;
    height: 22px;
    font-size: 18px;
  }

  .button {
    top: 240px;
    height: 22px;
    font-size: 14px;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
  .logo {
    width: 150px;
    padding: 10px;
  }

  .box {
    top: 90px;
    height: 350px;
  }

  .label {
    padding-top: 10px;
    font-size: 20px;
  }

  .password {
    top: 135px;
    height: 18px;
    font-size: 14px;
    border-radius: 3px;
  }

  .button {
    min-width: 50px;
    top: 200px;
    width: 40px;
    height: 16px;
    font-size: 12px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .logo {
    width: 150px;
    padding: 10px;
  }

  .box {
    top: 80px;
    height: 250px;
  }

  .label {
    padding-top: 5px;
    font-size: 19px;
  }

  .password {
    top: 105px;
    height: 16px;
    font-size: 14px;
  }

  .button {
    top: 150px;
    height: 16px;
    font-size: 12px;
  }
}

/* Landscape phones and down */
@media (max-width: 480px) {
  .logo {
    width: 120px;
  }

  .box {
    min-width: 150px;
  }

  .label {
    padding-top: 5px;
    font-size: 18px;
  }

  .password {
    top: 100px;
    height: 14px;
    font-size: 12px;
  }

  .button {
    top: 140px;
    height: 14px;
    font-size: 10px;
    min-width: 40px;
  }
}
