.logo {
  width: 100px;
  margin-left: 10px;
}

.navbar {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  padding: 4px;
  background-color: #ffffff;
  border: 1px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 1px #dddddd;
}

.box {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 92%;
  max-width: 1896px;
  height: calc(100% - 120px);
  padding: 15px;
  top: 70px;
  background-color: #ffffff;
  border: 1px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 1px #dddddd;
}

.title {
  position: fixed;
  text-align: left;
  color: #2e3192;
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
}

.subtitle {
  position: fixed;
  text-align: left;
  color: #2e3192;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
}
